import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Seo from "../components/seo";
import { _localizedSlug } from "../core/utils";

export const query = graphql`
  query CategoryBySlug($uid: String!, $lang: String!) {
    prismicCategory(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        title {
          text
        }
      }
    }
    allPrismicArticle(
      filter: { data: { category: { uid: { eq: $uid } } }, lang: { eq: $lang } }
    ) {
      nodes {
        type
        uid
        lang
        data {
          title {
            text
          }
          image {
            ...sharp
          }
        }
      }
    }
  }
`;

const Category = ({ data }) => {
  const { prismicCategory, allPrismicArticle } = data;
  // console.log(allPrismicArticle);
  return (
    <>
      <Seo
        pageTitle={prismicCategory.data.title[0].text}
        pageDescription={""}
        pageImage="https://images.prismic.io/baekbooks/7a2100c5-70df-48fe-9353-207f62d8669c_carte.png?auto=compress%2Cformat"
        template="template-category"
        page={true}
      />
      <div className="liste">
        <div className="row bottom-xs">
          {allPrismicArticle.nodes.map((node, i) => (
            <div className="col-md-3 col-xs-12 mb-md" key={i}>
              <Link to={_localizedSlug(node)}>
                <Img {...node.data.image} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Category;
